import React from "react";

const TextFieldRow = ({
  label,
  name,
  value,
  type = "text",
  className = "",
  helperText = "",
  ...props
}) => {
  return(
    <div className="form-group row">
      <label className="col-xl-3 col-lg-3 col-form-label">
        {label}
      </label>
      <div className="col-lg-9 col-xl-6">
        <input
          className={`form-control form-control-lg form-control-solid ${className}`}
          placeholder={label}
          type={type}
          name={name}
          defaultValue={value}
          {...props}
        />
        {helperText &&(
          <span className={"form-text text-muted"}>{helperText}</span>
        )}
      </div>
    </div>
  )
}

export default TextFieldRow;
