import React from "react";
import moment from "moment";

const DateCompletedColumnFormatter = (
        cellContent,
        row
) => {
  return (
    <span>
      {row.date_end_review ? moment(row.date_end_review).format("MM/DD/YYYY") : ""}
    </span>
  )
}

export default DateCompletedColumnFormatter;
