import React from "react";

const StatusColumnFormatter = (
  cellContent,
  row
) => {
  return (
    <span className={`text-capitalize font-italic`}>
      {row.status.replace("_", " ")}
    </span>
  )
}

export default StatusColumnFormatter;
