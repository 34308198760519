import React from "react";

const UserTypeColumnFormatter = (
        cellContent,
        row
) => {
  let user_type = {
    "user": "Subscriber",
    "admin": "Administration"
  };

  return (
    <span className="text-muted font-weight-bold">
      {row.roles.length > 0 ? user_type[row.roles[0]["name"]] : "Subscriber"}
    </span>
  )
}

export default UserTypeColumnFormatter;
