import React from 'react';
import { headerSortingClasses, sortCaret } from "~helpers";
import {
  CurrentStatusFormatter,
  LastResumeUpdateFormatter,
  LookingForFormatter,
  CountryFormatter,
  HomeLocationFormatter
} from "~pages/Reports/OnboardingList/components/column-formatters";
import {
  HeaderFormatter
} from "~pages/Reports/OnboardingList/components/column-header-formatters";
import globalHook from 'use-global-hook';

const columns = [
  {
    dataField: "id",
    text: "ID",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    style: { width: "70px" },
    headerClasses: "align-baseline"
  },
  {
    dataField: "fname",
    text: "First Name",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    classes: "",
    headerClasses: "align-baseline"
  },
  {
    dataField: "lname",
    text: "Last Name",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    classes: "",
    headerClasses: "align-baseline"
  },
  {
    dataField: "email",
    text: "Email",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    classes: "",
    headerClasses: "align-baseline"
  },
  {
    dataField: "profile->user_current_status",
    text: "Current status",
    sort: false,
    headerFormatter: HeaderFormatter,
    formatter: CurrentStatusFormatter,
    headerClasses: "align-baseline w-200px",
    classes: "",
  },
  {
    dataField: "profile->last_resume_update",
    text: "Last resume update",
    sort: false,
    headerFormatter: HeaderFormatter,
    formatter: LastResumeUpdateFormatter,
    headerClasses: "align-baseline w-200px",
    classes: "",
  },
  {
    dataField: "profile->role_employment",
    text: "Looking for (Role)",
    sort: false,
    headerFormatter: HeaderFormatter,
    formatter: LookingForFormatter,
    headerClasses: "align-baseline w-200px",
    classes: "",
  },
  {
    dataField: "profile->country",
    text: "Country",
    sort: false,
    headerFormatter: HeaderFormatter,
    formatter: CountryFormatter,
    headerClasses: "align-baseline w-200px",
    classes: "",
  },
  {
    dataField: "profile->employee_live",
    text: "Home location",
    sort: false,
    headerFormatter: HeaderFormatter,
    formatter: HomeLocationFormatter,
    headerClasses: "align-baseline w-200px",
    classes: "",
  },
];

const initialState = {
  columns: columns,
  defaultSorted: [{ dataField: "id", order: "asc" }],
  sizePerPageList: [
    { text: "10", value: 10 },
    { text: "20", value: 20 },
    { text: "50", value: 50 },
    { text: "100", value: 100 }
  ],
  queryParams: {
    sortOrder: "asc",
    sortField: "id",
    pageNumber: 1,
    pageSize: 10,
  },
  filter: {
    userType: "",
    subscriptionType: "",
    searchText: "",
    profileFilter: {
      user_current_status: "All",
      last_resume_update: "All",
      role_employment: "All",
      country: "All",
      employee_live: "All",
    },
  },
};

const state = initialState;

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  }
};

const useOnboardingList = globalHook(React, state, actions);

export default useOnboardingList;
