import React from "react";
import moment from "moment";

const PremiumSubscriptionDateFormatter = (
  cellContent,
  row
) => {
  return (
    <span className="text-muted font-weight-bold">
      {row.free_access && (
        <div>
          {!row.free_access.expired_at ? "Lifetime Free Access"
            : <span>
                {`${moment(row.free_access.created_at).format('MM/DD/YYYY')} - `}
                {moment(row.free_access.expired_at).format('MM/DD/YYYY')}
              </span>}
        </div>
      )}
      {row.subscriptions.length > 0
        ? (<>
          {row.subscriptions.map(item => {
            return(
              <div key={item.id}>
                <div className={`text-capitalize`}>
                  {item.name === "cover_letter" ? "Cover " : `${item.name.replace("_", " ")} `}
                  ({`${moment(item.paid_at).format('MM/DD/YYYY')} - `}
                  {`${moment(item.paid_at).add(1,'months').format('MM/DD/YYYY')}`})
                </div>
              </div>
            )
          })}
        </>)
        : ""
      }
    </span>
  )
}

export default PremiumSubscriptionDateFormatter;
