import React, { useEffect } from "react";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker } from 'react-date-range';
import { CustomDialog } from "~components";
import { useOnboardingReport } from "~store";
import { format } from "date-fns";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

const OnboardingFilter = () => {

  const [reportState, reportActions] = useOnboardingReport();

  useEffect(() => {
    const fetch = async () => {
      await reportActions.fetchStats();
    }
    fetch();
  }, [reportState.dateFilter])

  return (
    <CustomDialog
      dividers={false}
      dialogActions={false}
      triggerButton={
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>Filter by Date Range</Tooltip>}
        >
          <button type="button" className="btn btn-light-primary font-weight-bold btn-sm">
            {format(reportState.dateFilter.startDate, 'MMM dd, yyy')} - {format(reportState.dateFilter.endDate, 'MMM dd, yyyy')}
            <i className={"far fa-calendar-alt icon-sm-ms ml-2"} />
          </button>
        </OverlayTrigger>
      }
    >
      <DateRangePicker
        onChange={item => reportActions.setState({dateFilter: item.selection})}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={2}
        ranges={[reportState.dateFilter]}
        direction="horizontal"
      />
    </CustomDialog>
  );
}

export default OnboardingFilter;
