import React, { lazy } from 'react';
import globalHook from 'use-global-hook';

const Login = lazy(() => import("~pages/Auth/Login"));
const Registration = lazy(() => import("~pages/Auth/Registration"));
const ForgotPassword = lazy(() => import("~pages/Auth/ForgotPassword"));

const PersonaInformation = lazy(() => import("~pages/MyProfile/PersonaInformation"));
const AccountInformation = lazy(() => import("~pages/MyProfile/AccountInformation"));
const ChangePassword = lazy(() => import("~pages/MyProfile/ChangePassword"));

const Dashboard = lazy(() => import("~pages/Dashboard"));
const BuilderPage = lazy(() => import("~pages/BuilderPage"));

const UserManagement = lazy(() => import("~pages/UserManagement"));
const UserProfile = lazy(() => import("~pages/UserProfile"));
const UserEdit = lazy(() => import("~pages/UserEdit"));
const UserCreate = lazy(() => import("~pages/UserCreate"));

const ReviewResume = lazy(() => import("~pages/ReviewResume/List"));
const ViewResume = lazy(() => import("~pages/ReviewResume/ViewResume"));
const ReviewResumeEditor = lazy(() => import("~pages/ReviewResume/ResumeEditor"));
const ReviewResumeViewer = lazy(() => import("~pages/ReviewResume/ResumeEditor/resume/ResumeViewer"));

const ResumeEditor = lazy(() => import("~pages/ContentEditor/Resume"));
const CoverLetterEditor = lazy(() => import("~pages/ContentEditor/CoverLetter"));

const ReportsOnboardingCharts = lazy(() => import("~pages/Reports/OnboardingCharts"));
const ReportsOnboardingList = lazy(() => import("~pages/Reports/OnboardingList"));
const ReportsSalesReports = lazy(() => import("~pages/Reports/SalesReports"));

const state = {
  routes: [
    { path: "/login", component: Login, exact: true, private: false },
    { path: "/registration", component: Registration, exact: true, private: false },
    { path: "/forgot-password", component: ForgotPassword, exact: true, private: false },

    { path: "/my-profile/personal-information", component: PersonaInformation, exact: true, private: true },
    { path: "/my-profile/account-information", component: AccountInformation, exact: true, private: true },
    { path: "/my-profile/change-password", component: ChangePassword, exact: true, private: true },

    { path: "/", redirect: "/dashboard", exact: true, private: true },
    { path: "/dashboard", component: Dashboard, exact: true, private: true },
    { path: "/builder", component: BuilderPage, exact: true, private: true },

    { path: "/user-management", component: UserManagement, exact: true, private: true },
    { path: "/user-management/user-profile/:user_id", component: UserProfile, exact: true, private: true },
    { path: "/user-management/user-edit/:user_id", component: UserEdit, exact: true, private: true },
    { path: "/user-management/user-create", component: UserCreate, exact: true, private: true },

    { path: "/review-resume", component: ReviewResume, exact: true, private: true },
    { path: "/review-resume/view-resume/:id", component: ViewResume, exact: true, private: true },
    { path: "/review-resume/resume/:id/:ruid", component: ReviewResumeEditor, exact: true, private: true },
    { path: "/review-resume/resume-viewer", component: ReviewResumeViewer, exact: true, private: true },

    { path: "/content-editor/resume/pre-written-summaries", component: ResumeEditor, exact: true, private: true },
    { path: "/content-editor/resume/job-title", component: ResumeEditor, exact: true, private: true },
    { path: "/content-editor/resume/pre-written-responsibilities", component: ResumeEditor, exact: true, private: true },
    { path: "/content-editor/resume/pre-written-achievements", component: ResumeEditor, exact: true, private: true },
    { path: "/content-editor/resume/professional-skills", component: ResumeEditor, exact: true, private: true },
    { path: "/content-editor/resume/technical-skills", component: ResumeEditor, exact: true, private: true },
    { path: "/content-editor/resume/personal-attributes", component: ResumeEditor, exact: true, private: true },
    { path: "/content-editor/resume/additional-languages", component: ResumeEditor, exact: true, private: true },
    { path: "/content-editor/resume/pre-written-last-phrases", component: ResumeEditor, exact: true, private: true },

    { path: "/content-editor/cover-letter/pre-written-greetings", component: CoverLetterEditor, exact: true, private: true },
    { path: "/content-editor/cover-letter/pre-written-opener-content", component: CoverLetterEditor, exact: true, private: true },
    { path: "/content-editor/cover-letter/pre-written-body", component: CoverLetterEditor, exact: true, private: true },
    { path: "/content-editor/cover-letter/pre-written-gap-content", component: CoverLetterEditor, exact: true, private: true },
    { path: "/content-editor/cover-letter/pre-written-call-to-action", component: CoverLetterEditor, exact: true, private: true },
    { path: "/content-editor/cover-letter/pre-written-close", component: CoverLetterEditor, exact: true, private: true },

    { path: "/reports/onboarding-charts", component: ReportsOnboardingCharts, exact: true, private: true },
    { path: "/reports/onboarding-list", component: ReportsOnboardingList, exact: true, private: true },
    { path: "/reports/sales-reports", component: ReportsSalesReports, exact: true, private: true },
  ]
};

const actions = {
  isPrivate: (store, path) => {
    let res = store.state.routes.filter(route => route.path === path && route.private);
    return res.length > 0;
  },
  isFound: (store, path) => {
    let res = store.state.routes.filter(route => route.path === path);
    return res.length > 0;
  },
};

const useRoute = globalHook(React, state, actions);

export default useRoute;
