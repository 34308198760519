import React, { useState, useEffect } from "react";

const AvatarFieldRow = ({
  avatar = "",
  name = "avatar",
  label = "Photo",
  labelClass = "text-left"
}) => {

  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [removeAvatar, setRemoveAvatar] = useState("");

  useEffect(() => {
    setImagePreviewUrl(avatar);
  }, [avatar])

  const handleImageChange = (event) => {
    if(event.target.files.length > 0) {

      setRemoveAvatar("");

      let reader = new FileReader();
      let file = event.target.files[0];

      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
      }

      reader.readAsDataURL(file);
    }
  }

  const handleRemoveImage = () => {
    setImagePreviewUrl("");
    setRemoveAvatar(1);
    document.getElementsByName(name)[0].value = "";
  }

  return(
    <div className="form-group row">
      <label className={`col-xl-3 col-lg-3 col-form-label ${labelClass}`}>
        {label}
      </label>
      <div className="col-lg-9 col-xl-6">
        <div
          className="image-input image-input-outline"
          style={{ backgroundImage: `url("/media/users/blank.png")` }}
        >
          <div className="image-input-wrapper">
            {imagePreviewUrl && <img alt={""} className={"h-100 w-100"} src={imagePreviewUrl}/>}
          </div>
          <label
            className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
            data-action="change"
            data-toggle="tooltip"
            title=""
            data-original-title="Change avatar"
          >
            <i className="fa fa-pen icon-sm text-muted"></i>
            <input
              type="file"
              name={name}
              accept=".png, .jpg, .jpeg"
              onChange={(event) => handleImageChange(event)}
            />
            <input type="hidden" name="remove_avatar" value={removeAvatar} />
          </label>
          <span
            onClick={() => handleRemoveImage()}
            className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
            data-action="remove"
            data-toggle="tooltip"
            title=""
            data-original-title="Remove avatar"
          >
            <i className="ki ki-bold-close icon-xs text-muted"></i>
          </span>
        </div>
        <span className="form-text text-muted">
            Allowed file types: png, jpg, jpeg.
          </span>
      </div>
    </div>
  )
}

export default AvatarFieldRow;
