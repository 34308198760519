import React from "react";

const CurrentStatusFormatter = (
  cellContent,
  row
) => {
  return (
    <span>
      {row.profile && row.profile.hasOwnProperty('user_current_status') && row.profile.user_current_status}
    </span>
  )
}

export default CurrentStatusFormatter;
