import React from "react";
import { NavLink } from "react-router-dom";

const AvatarColumnFormatter = (
        cellContent,
        row
) => {

  let avatar = row.avatar;

  if(avatar && avatar.indexOf("http") === -1) {
    avatar = process.env.REACT_APP_RESUME_URL + avatar;
  }

  return (
    <NavLink to={`/user-management/user-profile/${row.id}`}>
      <div className="symbol symbol-40 symbol-sm flex-shrink-0 symbol-light-primary">
          {avatar ? (
            <img className="" src={avatar} alt="" />
          )
          : (
            <span className="symbol-label font-size-h4 font-weight-bold text-uppercase">
              {row.fname.trim() !== "" ? row.fname.charAt(0) :
                (row.lname.trim() !== "" ? row.fname.charAt(0) : row.email.charAt(0))
              }
            </span>
          )}
      </div>
    </NavLink>
  )
}

export default AvatarColumnFormatter;
