import React, {memo, useEffect, useState, Fragment} from "react";
import { useLocation } from "react-router-dom";
import SVG from "react-inlinesvg";
import { Select } from "~components";
import {
  Grid,
  Button,
  Tooltip,
  Chip
} from "@material-ui/core";
import {
  CustomTextField,
  FormErrorMessage,
  FormSuccessMessage
} from "~components";
import {
  useEditorField,
  useEditorFieldOption
} from "~store";

const ParentOptionSelect = memo(({ currentParents }) => {

  const [fieldState, ] = useEditorField();
  const [optionState, optionActions] = useEditorFieldOption();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      if(fieldState.field.slug) {
        let options = await optionActions.fetchOptionsByParentSlug(fieldState.fieldParentSlug[fieldState.field.slug].parentSlug);
        setOptions(options);
      }
    }
    fetch();
  }, []);

  const handleAddParent = () => {
    optionActions.setState({
      option: {
        ...optionState.option,
        parent_option_id: optionState.option.parent_option_id.concat([""])
      }
    });
  }

  const handleRemoveParent = (index) => {
    let newParents = optionState.option.parent_option_id.filter((item, i) => i !== index);
    optionActions.setState({
      option: {
        ...optionState.option,
        parent_option_id: newParents
      }
    });
  }

  const handleSelectChange = (event) => {
    let index = event.target.getAttribute('data-id');
    let newParents = optionState.option.parent_option_id;
    newParents[index] = event.target.value;
    optionActions.setState({
      option: {
        ...optionState.option,
        parent_option_id: newParents
      }
    });
  }

  return(
    <Grid item xs={12}>
      <Grid container spacing={2}>
        {options.length > 0
          && currentParents.length > 0
          && currentParents.filter(item => item !== "").length > 0
          && (
            <Grid item xs={12}>
              <label className={`form-text mb-2 font-weight-bold`}>
                Current {fieldState.field && fieldState.field.parent && fieldState.field.parent.field}
              </label>
              {currentParents.map(parentId => {
                let parentOption = options.filter(item => parseInt(item.id) === parseInt(parentId)).pop();
                return(
                  <Fragment key={parentId}>
                    {parentOption && (
                      <Chip
                        label={parentOption.hasOwnProperty("option") && parentOption.option}
                        className={`mr-2 bg-gray-100`}
                      />
                    )}
                  </Fragment>
                )
              })}
            </Grid>
        )}
        <Grid container spacing={1} className={`my-3`}>
          <Grid item xs={12}>
            <label className={`form-text font-weight-bold`}>
              Select {fieldState.field && fieldState.field.parent && fieldState.field.parent.field}
            </label>
          </Grid>
          {options.length > 0
            && Array.isArray(optionState.option.parent_option_id)
            && optionState.option.parent_option_id.map((parentId, i) => {
              let isLastIndex = i === optionState.option.parent_option_id.length-1;
              return(
                <Fragment key={i}>
                  <Grid item xs={11}>
                    <Select
                      key={parentId}
                      data-id={i}
                      placeholder={fieldState.fieldParentSlug[fieldState.field.slug].placeholder}
                      options={options}
                      optionValueIndex="id"
                      optionTextIndex="option"
                      name={`parent_option_id[]`}
                      value={parentId !== "" ? parseInt(parentId) : ""}
                      handleChange={(event) => handleSelectChange(event)}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Tooltip arrow title={isLastIndex ? "Add" : "Remove"}>
                      <button
                        type={"button"}
                        className="btn btn-icon btn-light btn-sm mt-1"
                        onClick={() => isLastIndex ? handleAddParent() : handleRemoveParent(i)}
                      >
                        <span className={`svg-icon svg-icon-md ${isLastIndex ? "svg-icon-primary" : "svg-icon-danger"} `}>
                          <SVG src={isLastIndex ? "/media/svg/icons/Code/Plus.svg" : "/media/svg/icons/Code/Error-circle.svg"} />
                        </span>
                      </button>
                    </Tooltip>
                  </Grid>
                </Fragment>
              )
          })}
        </Grid>
      </Grid>
    </Grid>
  )
})

const OptionEditForm = ({ optionId }) => {

  const location = useLocation();
  const [fieldState, ] = useEditorField();
  const [optionState, optionActions] = useEditorFieldOption();
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);
  const [currentParents, setCurrentParents] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      let option = await optionActions.fetchOption(optionId);
      let parents = JSON.parse(JSON.stringify(option.parent_option_id));
      setCurrentParents(parents ? parents : []);
    }

    if(optionId) {
      fetch();
    }

    return () => {
      setCurrentParents([]);
      optionActions.resetOption();
    }
  }, []);


  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrors({});
    setSuccess(false);

    const formData = new FormData(event.target);
    // console.log(Object.fromEntries(formData));
    // for (const [key, value] of formData) {
    //   console.log('»', key, value)
    // }
    let res = await optionActions.saveOption(formData);

    if(res.hasOwnProperty('errors')) {
      setErrors(res.errors);
    } else {
      setErrors({});
      setSuccess(true);
      setCurrentParents(res.parent_option_id ? res.parent_option_id : [""]);

      setTimeout(
        function() {
          setSuccess(false);
        },
        6000
      );
    }
  }

  const handleFieldChange = (event) => {
    optionActions.setState({
      option: {
        ...optionState.option,
        [event.target.name]: event.target.value
      }
    });
  }

  return(
    <form
      onSubmit={handleSubmit}
      className={"mb-4"}
    >
      <input type={"hidden"} name={"id"} value={optionState.option.id} />
      <input type={"hidden"} name={"editor_field_id"} value={fieldState.field.id} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormErrorMessage errors={errors} />
          {success && <FormSuccessMessage message={"Option successfully saved."} />}
        </Grid>
        {(fieldState.field.slug === location.pathname.split('/').pop()
            && fieldState.fieldParentSlug.hasOwnProperty(fieldState.field.slug)
          ) && <ParentOptionSelect currentParents={currentParents} />
        }
        <Grid item xs={12}>
          <CustomTextField
            label={fieldState.field.field}
            labelClass={"font-weight-bold"}
            name={"option"}
            value={optionState.option.option}
            onChange={(event) => handleFieldChange(event)}
            multiline
            rows={1}
            rowsMax={7}
          />
        </Grid>
        <Grid item xs={12} className={"text-right"}>
          <Button type={"submit"} className={"btn btn-primary btn-elevate"}>
            Save
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default memo(OptionEditForm);
