import React, { memo } from "react";
import { Select } from "~components";
import { useSalesList } from "~store";

const statusOptions = [
  {value: "", text: "All"},
  {value: "succeeded", text: "Succeeded"},
  {value: "canceled", text: "Canceled"},
  {value: "refunded", text: "Refunded"}
];

const StatusHeaderFormatter = () => {

  const [salesListState, salesListActions] = useSalesList();

  const handleFilter = (event) => {
    let filter = salesListState.filter;
    filter[event.target.name]= event.target.value;

    salesListActions.setState({filter: filter});
  }

  return(
    <div>
      <div className={"text-center"}>
        STATUS
      </div>
      <div className={"text-center pt-1"}>
        <Select
          classes={"h-30px font-size-sm py-0 px-2"}
          options={statusOptions}
          name={"status"}
          value={salesListState.filter.status}
          handleChange={(event) => handleFilter(event)}
        />
      </div>
    </div>
  )
}

export default memo(StatusHeaderFormatter);
