import React from "react";
import moment from "moment";

const DateReviewedColumnFormatter = (
        cellContent,
        row
) => {
  return (
    <span>
      {row.date_start_review ? moment(row.date_start_review).format("MM/DD/YYYY") : ""}
    </span>
  )
}

export default DateReviewedColumnFormatter;
