import React, {memo} from "react";
import SVG from "react-inlinesvg";
import {
  Button,
  Tooltip
} from "@material-ui/core";
import { CustomDialog } from "~components";
import OptionEditForm from "./OptionEditForm";
import { useEditorFieldOption } from "~store";

const OptionEditModal = ({ optionId = false }) => {

  const [optionState, optionActions] = useEditorFieldOption();

  return (
    <CustomDialog
      title={optionState.option.id === "" ? "Create new" : "Update details"}
      maxWidth={"sm"}
      wrapperClassName={"mr-2"}
      dialogActions={false}
      dividers={false}
      onExit={() => optionActions.fetchOptions()}
      showProgressBar={optionState.loading}
      triggerButton={!optionId
        ? (
          <Button
            variant="contained"
            className={"btn btn-primary btn-elevate h-35px font-weight-bold"}
          >
            Create New
          </Button>
        )
        : (
          <Tooltip arrow title={"Edit"}>
            <button className="btn btn-icon btn-light btn-hover-primary btn-sm">
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG src={"/media/svg/icons/Communication/Write.svg"} />
              </span>
            </button>
          </Tooltip>
        )
      }
    >
      <OptionEditForm optionId={optionId} />
    </CustomDialog>
  )
}

export default memo(OptionEditModal);
