import React from "react";

const AmountReceivedColumnFormatter = (
  cellContent,
  row
) => {
  return (
    <div>
      $ {Number(row.pi_amount_received).toFixed(2)} {row.cs_currency}
    </div>
  );
}

export default AmountReceivedColumnFormatter;
