import React from "react";
import { Select } from "~components";
import { useUserList } from "~store";

const subscriptionTypeOptions = [
  {value: "", text: "All"},
  {value: "free", text: "Free"},
  {value: "free_upgrade", text: "Free Upgrade"},
  {value: "premium", text: "Premium"}
];

const SubscriptionTypeHeaderFormatter = () => {

  const [userListState, userListActions] = useUserList();

  const handleFilter = (event) => {
    let filter = userListState.filter;
    filter[event.target.name]= event.target.value;

    userListActions.setState({filter: filter});
  }

  return(
    <div>
      <div className={"text-center"}>
        SUBSCRIPTION TYPE
      </div>
      <div className={"text-center pt-1"}>
        <Select
          classes={"h-30px font-size-sm py-0 px-2"}
          options={subscriptionTypeOptions}
          name={"subscriptionType"}
          value={userListState.filter.subscriptionType}
          handleChange={(event) => handleFilter(event)}
        />
      </div>
    </div>
  )
}

export default SubscriptionTypeHeaderFormatter;
