import React from "react";

const ReviewerColumnFormatter = (
  cellContent,
  row
) => {
  return (
    <span>
      {row.reviewer !== null ? `${row.reviewer.fname} ${row.reviewer.lname}` : ""}
    </span>
  )
}

export default ReviewerColumnFormatter;
