import React from 'react';
import { headerSortingClasses, sortCaret } from "~helpers";
import {
  ActionsColumnFormatter,
  AvatarColumnFormatter,
  DateJoinedColumnFormatter,
  PremiumSubscriptionDateFormatter,
  UserTypeColumnFormatter,
  SubscriptionTypeColumnFormatter
} from "~pages/UserManagement/components/column-formatters";
import {
  UserTypeHeaderFormatter,
  SubscriptionTypeHeaderFormatter
} from "~pages/UserManagement/components/column-header-formatters";
import globalHook from 'use-global-hook';

const columns = [
  {
    dataField: "id",
    text: "ID",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    style: { width: "70px" },
    headerClasses: "align-baseline"
  },
  {
    dataField: "avatar",
    text: "",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    formatter: AvatarColumnFormatter,
    headerClasses: "align-baseline"
  },
  {
    dataField: "fname",
    text: "First Name",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    classes: "text-muted font-weight-bold",
    headerClasses: "align-baseline"
  },
  {
    dataField: "lname",
    text: "Last Name",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    classes: "text-muted font-weight-bold",
    headerClasses: "align-baseline"
  },
  {
    dataField: "email",
    text: "Email",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    classes: "text-muted font-weight-bold",
    headerClasses: "align-baseline"
  },
  {
    dataField: "created_at",
    text: "Date Joined",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    formatter: DateJoinedColumnFormatter,
    headerClasses: "text-center align-baseline",
    classes: "text-center",
  },
  {
    dataField: "user_roles",
    text: <UserTypeHeaderFormatter />,
    sort: false,
    sortCaret: sortCaret,
    headerSortingClasses,
    formatter: UserTypeColumnFormatter,
  },
  {
    dataField: "subscription_type",
    text: <SubscriptionTypeHeaderFormatter />,
    sort: false,
    sortCaret: sortCaret,
    headerSortingClasses,
    formatter: SubscriptionTypeColumnFormatter,
    headerClasses: "text-center align-baseline",
    classes: "text-center",
  },
  {
    dataField: "profile->premium_expiration",
    text: "Premium Subscription Date",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    formatter: PremiumSubscriptionDateFormatter,
    headerClasses: "text-center align-baseline",
    classes: "text-center text-muted font-weight-bold",
  },
  {
    dataField: "action",
    text: "Actions",
    formatter: ActionsColumnFormatter,
    classes: "text-right pr-0",
    headerClasses: "text-right pr-3 align-baseline",
    style: { minWidth: "125px" },
  },
];

const initialState = {
  columns: columns,
  defaultSorted: [{ dataField: "created_at", order: "desc" }],
  sizePerPageList: [
    { text: "10", value: 10 },
    { text: "20", value: 20 },
    { text: "50", value: 50 },
    { text: "100", value: 100 }
  ],
  queryParams: {
    sortOrder: "desc",
    sortField: "created_at",
    pageNumber: 1,
    pageSize: 10,
  },
  filter: {
    userType: "",
    subscriptionType: "",
    searchText: "",
  },
};

const state = initialState;

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  }
};

const useUserList = globalHook(React, state, actions);

export default useUserList;
