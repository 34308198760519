import React, {useState} from 'react';
import PropTypes from 'prop-types';

const Select = ({
  options = [], // [{value: "option1", text: "Option 1"} ...]
  optionValueIndex= "value",
  optionTextIndex= "text",
  classes = "",
  value = "",
  handleChange = false,
  placeholder= "",
  ...props
}) => {

  const [selected, setSelected] = useState(value);
  const handleOnChange = (event) => {
    setSelected(event.target.value);

    if (typeof handleChange === 'function') {
      handleChange(event);
    }
  }

  return(
    <select
      value={selected}
      className={`form-control form-control-solid ${classes}`}
      onChange={(event) => handleOnChange(event)}
      {...props}
    >
      {placeholder && (
        <option value="" className={"text-muted"}>
          {placeholder}
        </option>
      )}
      {options.map((option, i) => {
        return(
          <option
            key={i}
            value={option[optionValueIndex]}
            className={"text-dark-75"}
          >
            {option[optionTextIndex]}
          </option>
        )
      })}
    </select>
  )
}

Select.propTypes = {
  options: PropTypes.array.isRequired,
  optionValueIndex: PropTypes.string,
  optionTextIndex: PropTypes.string,
  // value: PropTypes.string,
  handleChange: PropTypes.func || PropTypes.bool,
  classes: PropTypes.string,
  placeholder: PropTypes.string,
};

export default Select;
