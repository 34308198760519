import React from 'react';
import globalHook from 'use-global-hook';
import moment from "moment";

const initialState = {
  loading: false,
  totalUsers: 0,
  users: [],
  allUsers: [],
  user: {
    id: "",
    lname: "",
    fname: "",
    email: "",
    contact: "",
    city: "",
    role: "",
    profile: {},
    password: "",
    password_confirmation: "",
    avatar: ""
  },
  downloads: [],
  downloadsMeta: {},
  accessLogs: [],
  accessLogsMeta: {},
  profileSummary: {},
  latestResume: {},
  accessLogsChart: {
    count: 0,
    keys: [],
    values: []
  },
  lastQuery: {},
  lastFilters: {},
  distinctCountries: [],
};

const state = JSON.parse(JSON.stringify(initialState));

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  fetchUsers: async (store, queryParams = {}, filters = {}, all = "") => {
    store.setState({ loading: true });

    if(Object.keys(queryParams).length !== 0
        && Object.keys(filters).length !== 0
    ){
      store.setState({
        lastQuery: queryParams,
        lastFilters: filters
      });
    }

    if(Object.keys(queryParams).length === 0){
      queryParams = store.state.lastQuery;
    }

    if(Object.keys(filters).length === 0){
      filters = store.state.lastFilters;
    }

    try{
      let params = `?page=${queryParams.pageNumber}
        &pageSize=${queryParams.pageSize}
        &sortField=${queryParams.sortField}
        &sortOrder=${queryParams.sortOrder}
        &userType=${filters.userType}
        &subscriptionType=${filters.subscriptionType}
        &searchText=${filters.searchText}
        &all=${all}`; // all=0 => paginate, all=1 => get all data

      if(filters.hasOwnProperty('profileFilter')) {
        for(let key of Object.keys(filters.profileFilter)) {
          if(filters.profileFilter[key] !== "All") {
            params += `&profileFilter[${key}]=${filters.profileFilter[key]}`;
          }
        }
      }

      params = params.replace("+", "%2B");

      let { data } = await global.axios({
        method: "GET",
        url: `/api/admin/user${params}`
      });

      if(all) {
        store.setState({ allUsers: data.data });
      } else {
          store.setState({
          users: data.data,
          totalUsers: data.meta.total
        });
      }

      store.setState({ loading: false });
    }
    catch (error) {
      console.log(error);
    }
  },
  fetchUser: async (store, user_id) => {
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/admin/user/${user_id}`
      });
      store.actions.setUser(data.data);
    }
    catch (error) {
      console.log(error);
    }
  },
  fetchDownloadsByUser: async (store, user_id, pageNum = 1) => {
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/admin/download_by_user/${user_id}?page=${pageNum}`
      });
      store.setState({
        downloads: data.data,
        downloadsMeta: data.meta
      });
    }
    catch (error) {
      console.log(error);
    }
  },
  fetchAccessLogsByUser: async (store, user_id, pageNum = 1) => {
    try {
      let params = `?pageSize=10&page=${pageNum}`;

      let { data } = await global.axios({
        method: "GET",
        url: `/api/admin/access_log_by_user/${user_id}${params}`
      });

      store.setState({
        accessLogs: data.data,
        accessLogsMeta: data.meta
      });
    }
    catch (error) {
      console.log(error);
    }
  },
  fetchAllAccessLogsByUser: async (store, user_id) => {
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/admin/access_log_by_user/${user_id}`
      });

      let logs = data.data;
      let newLogs = {};

      let m;
      for (m = 5; m >=0; m--) {
        let monthYear = moment().subtract(m, "month").format('MMMM YYYY');
        newLogs[monthYear] = 0;

        for(let i in logs) {
          let my = moment(logs[i].created_at).format("MMMM YYYY");
          if (my === monthYear) {
            newLogs[monthYear] += 1;
          }
        }
      }

      store.setState({
        accessLogsChart: {
          count: logs.length,
          keys: Object.keys(newLogs),
          values: Object.values(newLogs)
        }
      });
    }
    catch (error) {
      console.log(error);
    }
  },
  fetchProfileSummary: async (store, user_id) => {
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/admin/profile_summary/${user_id}`
      });
      store.setState({ profileSummary: data.data });
    }
    catch (error) {
      console.log(error);
    }
  },
  fetchLatestResume: async (store, user_id) => {
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/admin/latest_complete_resume_by_user/${user_id}`
      });
      store.setState({ latestResume: data });
    }
    catch (error) {
      console.log(error);
    }
  },
  saveUser: async (store, formData) => {

    let postData = store.actions.prepareFormData(formData);

    try {
      let url = store.state.user.id !== "" ? `/api/admin/user/${store.state.user.id}` : `/api/admin/user`;
      let config = {headers: { "Content-Type": "multipart/form-data" }};

      let { data } = await global.axios.post(url, postData, config);
      store.actions.setUser(data.data);

      return data.data;
    }
    catch ({ response }) {
      if(response.status === 422) {
        return response.data;
      }
    }
  },
  prepareFormData: (store, formData) => {

    let user = store.state.user;
    delete user.profile;
    delete user.roles;

    if(user.hasOwnProperty('password') && user.password === "") {
      delete user.password;
      delete user.password_confirmation;
    }

    let keys = [];
    for(let [i] of formData) {
      keys.push(i);
    }

    for (let key of Object.keys(user)) {
      if(keys.indexOf(key) === -1) {
        formData.append(key, user[key]);
      }
    }

    if(store.state.user.id !== "") {
      formData.append("_method", "PUT");
    }

    return formData;
  },
  saveAvatar: async (store, postData) => {

    try {
      let formData = new FormData();
      formData.append("avatar", postData.avatar);

      await global.axios.put(
        `${process.env.REACT_APP_RESUME_URL}api/update_avatar/${postData.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          }
        }
      );

      return true;
    }
    catch (error) {
      console.log(error);
      return false;
    }
  },
  deleteUser: async (store, user_id) => {
    try {
      await global.axios({
        method: "DELETE",
        url: `/api/admin/user/${user_id}`
      });

      store.actions.fetchUsers();

    }
    catch (error) {
      console.log(error);
    }
  },
  setUser: (store, user) => {

    user.role = user.roles[0]['name'];
    user.password = user.password_confirmation = "";

    if(user.profile) {
      user.contact = user.profile.hasOwnProperty('contact') ? user.profile.contact : "";
      user.city = user.profile.hasOwnProperty('city') ? user.profile.city : "";
    }

    if(user.avatar !== "" && user.avatar.indexOf("http") === -1) {
      user.avatar = process.env.REACT_APP_RESUME_URL + user.avatar;
    }

    store.setState({ user: user });
  },
  resetProfile: (store) => {
    store.setState({
      user: initialState.user,
      downloads: initialState.downloads,
      accessLogs: initialState.accessLogs,
      profileSummary: initialState.profileSummary,
      latestResume: initialState.latestResume,
    })
  },
  fetchDistinctCountries: async (store) => {
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/admin/distinct_countries`
      });
      let countries = [
        { country: "All" },
        ...data.data
      ];
      store.setState({ distinctCountries: countries });
    }
    catch (error) {
      console.log(error);
    }
  }
};

const useUser = globalHook(React, state, actions);

export default useUser;
