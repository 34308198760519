import React from 'react';
import { headerSortingClasses, sortCaret } from "~helpers";
import {
  ActionsColumnFormatter
} from "~pages/ContentEditor/Resume/components/column-formatters";
import globalHook from 'use-global-hook';

const columns = [
  {
    dataField: "count",
    text: "",
    sort: false,
    headerClasses: "w-40px"
  },
  {
    dataField: "option",
    text: "Option",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    headerClasses: "align-baseline pb-3"
  },
  {
    dataField: "",
    text: "Actions",
    sort: false,
    formatter: ActionsColumnFormatter,
    classes: "text-center",
    headerClasses: "text-center w-110px"
  },
];

const initialState = {
  columns: columns,
  defaultSorted: [{ dataField: "option", order: "asc" }],
  sizePerPageList: [
    { text: "10", value: 10 },
    { text: "20", value: 20 },
    { text: "50", value: 50 },
    { text: "100", value: 100 }
  ],
  queryParams: {
    sortOrder: "asc",
    sortField: "option",
    pageNumber: 1,
    pageSize: 10,
  },
  filter: {
    searchText: "",
    fieldId: "",
    parentOptionId: ""
  },
};

const state = initialState;

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
};

const useEditorFieldOptionList = globalHook(React, state, actions);

export default useEditorFieldOptionList;
