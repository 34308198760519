import React from "react";
import { Select } from "~components";
import { useUserList } from "~store";

const userTypeOptions = [
  {value: "", text: "All"},
  {value: "admin", text: "Administration"},
  {value: "user", text: "Subscriber"}
];

const UserTypeHeaderFormatter = () => {

  const [userListState, userListActions] = useUserList();

  const handleFilter = (event) => {
    let filter = userListState.filter;
    filter[event.target.name]= event.target.value;

    userListActions.setState({filter: filter});
  }

  return(
   <div>
     <div className={"text-center"}>
       USER TYPE
     </div>
     <div className={"text-center pt-1"}>
       <Select
         classes={"h-30px font-size-sm py-0 px-2"}
         options={userTypeOptions}
         name={"userType"}
         value={userListState.filter.userType}
         handleChange={(event) => handleFilter(event)}
       />
     </div>
   </div>
  )
}

export default UserTypeHeaderFormatter;
