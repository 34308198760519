import React from "react";

const OwnerColumnFormatter = (
  cellContent,
  row
) => {
  return (
    <span>
      {row.resume.owner.fname} {row.resume.owner.lname}
    </span>
  )
}

export default OwnerColumnFormatter;
