import React, {memo, useEffect} from "react";
import { Select } from "~components";
import { useUser, useOnboardingList } from "~store";

const options = {
  "profile->user_current_status": [
    {value: "All"},
    {value: "Employed and looking to change companies"},
    {value: "Employed and applying for an internal promotion"},
    {value: "Not employed and searching for a new opportunity"},
    {value: "Stood down because of COVID-19"},
    {value: "A university graduate"},
    {value: "A school leaver/student"},
    {value: "Returning to work after a break due to maternity leave, illness or other"},
    {value: "Just wanting to update my resume in case the perfect opportunity arises"},
  ],
  "profile->last_resume_update": [
    {value: "All"},
    {value: "I’ve never had a formal resume"},
    {value: "0-1 years"},
    {value: "2-5 years"},
    {value: "5-10 years"},
    {value: "10-20 years"},
    {value: "20+ years"},
  ],
  "profile->role_employment": [
    {value: "All"},
    {value: "Traditional 9 - 5 full time employment"},
    {value: "Traditional 9 - 5 part time employment"},
    {value: "Casual employment"},
    {value: "Contract / project work"},
    {value: "Work from home / remote working opportunity"},
    {value: "A side gig to supplement my income"},
    {value: "I'll take whatever I can get"},
    {value: "Other"},
  ],
  "profile->employee_live": [
    {value: "All"},
    {value: "Capital city"},
    {value: "Regional area"},
    {value: "Remote area"},
  ]
};

const SelectOptions = memo(({ column }) => {

  const [userListState, userListActions] = useOnboardingList();
  const [userState, userActions] = useUser();

  useEffect(() => {
    if(column.dataField === "profile->country") {
      userActions.fetchDistinctCountries();
    }
  }, [])

  const handleFilter = (event) => {
    userListActions.setState({
      filter: {
        ...userListState.filter,
        profileFilter: {
          ...userListState.filter.profileFilter,
          [event.target.name]: event.target.value
        }
      }
    });
  }

  return(
    <Select
      classes={"h-30px font-size-sm py-0 px-2"}
      optionTextIndex={column.dataField === "profile->country" ? "country" : "value"}
      options={column.dataField === "profile->country" ? userState.distinctCountries : options[column.dataField]}
      name={column.dataField.split("->")[1]}
      value={userListState.filter.userType}
      handleChange={(event) => handleFilter(event)}
    />
  )
})

const HeaderFormatter = (column, { sortElement }) => {
  return(
   <div>
     <div className={"text-center"}>
       {column.text}
       {sortElement}
     </div>
     <div className={"text-center pt-1"}>
       <SelectOptions column={column} />
     </div>
   </div>
  )
}

export default HeaderFormatter;
