import React from "react";

const LookingForFormatter = (
  cellContent,
  row
) => {
  return (
    <span>
      {row.profile && row.profile.hasOwnProperty('role_employment') && row.profile.role_employment}
    </span>
  )
}

export default LookingForFormatter;
