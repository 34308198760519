import React, { memo } from "react";
import { useSales } from "~store";

const AmountReceivedHeaderFormatter = () => {

  const [salesState, ] = useSales();

  return(
    <div>
      <div className={"text-center"}>
        PAYMENT RECEIVED
      </div>
      <div className={"text-center pt-1"}>
        (<span className={"font-size-xs font-weight-bolder text-dark text-lowercase"}>$ &nbsp;
          {salesState.salesTotal}
        &nbsp; aud</span>)
      </div>
    </div>
  )
}

export default memo(AmountReceivedHeaderFormatter);
