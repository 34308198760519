import React from "react";
import PropTypes from 'prop-types';

const LoadingOverlay = ({
  message = "Please wait..."
}) => {
  return(
    <div className="loading-overlay d-flex">
      <div className="spinner spinner-right spinner-primary bg-white px-5 py-3 w-150px rounded-sm h-40px m-auto">
        {message}
      </div>
    </div>
  )
}

LoadingOverlay.propTypes = {
  message: PropTypes.string
}

export default LoadingOverlay;
