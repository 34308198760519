import React from "react";
import OptionEditModal from "../OptionEditModal";
import OptionDelete from "../OptionDelete";

const ActionsColumnFormatter = (
  cellContent,
  row
) => {
  return (
    <div>
      <OptionEditModal optionId={row.id} />
      <OptionDelete row={row} />
    </div>
  );
}

export default ActionsColumnFormatter;
