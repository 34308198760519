import React from "react";
import moment from "moment";

const DeadlineColumnFormatter = (
        cellContent,
        row
) => {
  return (
    <span>
      {row.deadline ? moment(row.deadline).format("MM/DD/YYYY") : ""}
    </span>
  )
}

export default DeadlineColumnFormatter;
