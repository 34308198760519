import React from "react";

const CountryFormatter = (
  cellContent,
  row
) => {
  return (
    <span>
      {row.profile && row.profile.hasOwnProperty('country') && row.profile.country}
    </span>
  )
}

export default CountryFormatter;
