import React from "react";

const RefundAmountColumnFormatter = (
  cellContent,
  row
) => {
  return (
    <span>
      {(row.re_amount > 0) && (
        <>
          $ {Number(row.re_amount).toFixed(2)} aud
        </>
      )}
    </span>
  );
}

export default RefundAmountColumnFormatter;
