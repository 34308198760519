import React, {useEffect} from "react";
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const CustomDatePicker = ({
  date = new Date(),
  handleChange = false,
  label = "Date",
  format = "MM/dd/yyyy",
  ...props
}) => {
  const [selectedDate, setSelectedDate] = React.useState(date);

  useEffect(() => {
    setSelectedDate(date);
  }, [date])

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if(typeof handleChange === "function") {
      handleChange(date);
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        autoOk={true}
        disableToolbar
        variant="inline"
        margin="dense"
        label={label}
        value={selectedDate}
        format={format}
        onChange={handleDateChange}
        inputVariant={"outlined"}
        {...props}
      />
    </MuiPickersUtilsProvider>
  )
}

CustomDatePicker.propTypes = {
  date: PropTypes.object || PropTypes.string,
  label: PropTypes.string,
  format: PropTypes.string,
  handleChange: PropTypes.func || PropTypes.bool
};

export default CustomDatePicker;
