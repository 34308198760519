import React from 'react';
import globalHook from 'use-global-hook';

const initialState = {
  account_ask_experts: "Ask experts",
  account_billing: "Billing",
  account_covers_index: "View cover letter",
  account_covers_store: "Saved cover letter",
  account_covers_delete: "Deleted cover letter",
  account_covers_create: "Created cover letter",
  account_covers_new_pdf: "Cover new PDF",
  account_covers_from_resume: "Covers from resume",
  account_covers_show: "View cover letter",
  account_covers_update: "Update cover letter",
  account_covers_clone: "clone cover letter",
  account_covers_download: "download cover letter",
  account_covers_edit: "View cover letter ",
  account_downloads: "View account downloads",
  account_help_desk: "Help desk",
  account_helpdesk_email: "Help desk Email",
  account_orders: "Account order",
  account_payment_method: "Account payment method",
  account_: "Account",
  account_profile: "Profile",
  account_resumes_store: "Saved resume",
  account_resumes_index: "View resume",
  account_resumes_create: "Create resume",
  account_resumes_new_pdf: "Resume new PDF",
  account_resumes_delete: "Deleted resume",
  account_resumes_update: "Updated resume",
  account_resumes_show: "View Resume",
  account_resumes_clone: "Clone resume",
  account_resumes_download: "Download resume",
  account_resumes_edit: "View resume",
  account_upgrade: "Account upgrade",
  account_upgrade_premium: "Upgrade premium",
  account_upgrade_single: "Upgrade single",
  socialite_auth: "Login via Google",
  billinginformation: "Billing information",
  checkout: "Checkout",
  error_page: "Error Page",
  faq: "FAQ",
  gifts_pay: "Gifts pay",
  login: "Logged in",
  auto_login: "Auto Login",
  logout: "Logout",
  onboard_post: "Onboard Post",
  onboarding: "Onboarding",
  orderinformation: "Order information",
  password_confirm: "Password confirm",
  password_email: "Password email",
  password_update: "Password update",
  password_request: "Password request",
  password_reset: "Password reset",
  payments_pay: "Payment pay",
  payments_cancel: "Payment cancel",
  payments_paid: "Payment paid",
  payments_process: "Payment process",
  pricing: "Pricing",
  register: "Register",
  resume_1: "Resume 1",
  cashier_payment: "Cashier payment",
  cashier_webhook: "Cashier Webhook",
};

const state = initialState;

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  getRouteDescription: (store, log) => {
    let index = log.route ? log.route : new URL(log.url).pathname.substr(1);
    index = index.replace(/\./g, "_")
            .replace(/-/g, "_")
            .replace(/\//g, "_")
            .trim();
    return store.state.hasOwnProperty(index) ? store.state[index] : index;
  }
};

const useUserAccessLogs = globalHook(React, state, actions);

export default useUserAccessLogs;
