import React from "react";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker } from 'react-date-range';
import { ModalProgressBar } from "~partials/controls";
import { CustomDialog } from "~components";
import { format } from "date-fns";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import { useSales, useSalesList } from "~store";

const SalesDateFilter = () => {

  const [salesListState, salesListActions] = useSalesList();
  const [salesState, ] = useSales();

  const handleChangeDate = (DateFilter) => {
    let filter = salesListState.filter;
    filter.dateFilter = DateFilter;
    salesListActions.setState({ filter: filter })
  }
  return (

    <CustomDialog
      dividers={false}
      dialogActions={false}
      showProgressBar={salesState.loading}
      triggerButton={
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>Filter by Date Range</Tooltip>}
        >
          <button type="button" className="btn btn-light-primary font-weight-bold btn-sm">
            {format(salesListState.filter.dateFilter.startDate, 'MMM dd, yyy')} - {format(salesListState.filter.dateFilter.endDate, 'MMM dd, yyyy')}
            <i className={"far fa-calendar-alt icon-sm-ms ml-2"} />
          </button>
        </OverlayTrigger>
      }
    >
      <DateRangePicker
        onChange={item => handleChangeDate(item.selection)}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={2}
        ranges={[salesListState.filter.dateFilter]}
        direction="horizontal"
      />
    </CustomDialog>
  );
}

export default SalesDateFilter;
