import React from 'react';
import globalHook from 'use-global-hook';
import { headerSortingClasses, sortCaret } from "~helpers";
import {
  AmountColumnFormatter,
  DeadlineColumnFormatter,
  DateCreatedColumnFormatter,
  OwnerColumnFormatter,
  ResumeNameColumnFormatter,
  ActionsColumnFormatter,
  StatusColumnFormatter,
  DateReviewedColumnFormatter,
  DateCompletedColumnFormatter,
  ReviewerColumnFormatter
} from "~pages/ReviewResume/List/components/column-formatters";

const columns = [
  {
    dataField: "id",
    text: "ID",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    headerClasses: "align-middle w-60px"
  },
  {
    dataField: "created_at",
    text: "Date Created",
    sort: true,
    sortCaret: sortCaret,
    formatter: DateCreatedColumnFormatter,
    headerSortingClasses,
    headerClasses: "align-middle text-center",
    classes: "text-center w-150px",
  },
  {
    dataField: "origin_resume_ruid",
    text: "Owner",
    sort: false,
    sortCaret: sortCaret,
    headerSortingClasses,
    headerClasses: "align-middle",
    formatter: OwnerColumnFormatter
  },
  {
    dataField: "resume_ruid",
    text: "Resume Name",
    sort: false,
    sortCaret: sortCaret,
    headerSortingClasses,
    headerClasses: "align-middle",
    formatter: ResumeNameColumnFormatter
  },
  {
    dataField: "deadline",
    text: "Deadline",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    classes: "",
    headerClasses: "align-middle",
    formatter: DeadlineColumnFormatter,
  },
  {
    dataField: "payment_amount",
    text: "Amount",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    classes: "",
    headerClasses: "align-middle",
    formatter: AmountColumnFormatter,
  },
  {
    dataField: "reviewer",
    text: "Reviewer",
    sort: false,
    sortCaret: sortCaret,
    headerSortingClasses,
    classes: "text-center",
    headerClasses: "align-middle text-center",
    formatter: ReviewerColumnFormatter,
  },
  {
    dataField: "date_start_review",
    text: "Date Reviewed",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    classes: "text-center",
    headerClasses: "align-middle text-center",
    formatter: DateReviewedColumnFormatter,
  },
  {
    dataField: "date_end_review",
    text: "Date Completed",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    classes: "",
    headerClasses: "align-middle text-center",
    formatter: DateCompletedColumnFormatter,
  },
  {
    dataField: "status",
    text: "Status",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    classes: "text-center",
    headerClasses: "align-middle text-center",
    formatter: StatusColumnFormatter
  },
  {
    dataField: "action",
    text: "Actions",
    formatter: ActionsColumnFormatter,
    classes: "text-right pr-0",
    headerClasses: "text-right pr-3 align-middle",
    style: { minWidth: "125px" },
  },
];

const initialState = {
  columns: columns,
  defaultSorted: [{ dataField: "id", order: "desc" }],
  sizePerPageList: [
    { text: "10", value: 10 },
    { text: "20", value: 20 },
    { text: "50", value: 50 },
    { text: "100", value: 100 }
  ],
  queryParams: {
    sortOrder: "desc",
    sortField: "created_at",
    pageNumber: 1,
    pageSize: 10,
  },
  filter: {
    searchText: "",
  },
};

const state = initialState;

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
};

const useReviewResumeList = globalHook(React, state, actions);

export default useReviewResumeList;
