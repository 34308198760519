import React from 'react';
import { headerSortingClasses, sortCaret } from "~helpers";
import {
  AmountReceivedColumnFormatter,
  CustomerColumnFormatter,
  ProductAmountColumnFormatter,
  RefundAmountColumnFormatter,
  StatusColumnFormatter
} from "~pages/Reports/SalesReports/components/column-formatters";
import {
  AmountReceivedHeaderFormatter,
  StatusHeaderFormatter
} from "~pages/Reports/SalesReports/components/column-header-formatters";
import globalHook from 'use-global-hook';

const columns = [
  {
    dataField: "count",
    text: "",
    sort: false,
    sortCaret: sortCaret,
    headerSortingClasses,
    headerClasses: "align-baseline pb-3"
  },
  {
    dataField: "pi_created",
    text: "Date",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    headerClasses: "align-baseline pb-3"
  },
  {
    dataField: "users.fname",
    text: "Customer",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    formatter: CustomerColumnFormatter,
    headerClasses: "align-baseline pb-3"
  },
  {
    dataField: "customer_email",
    text: "Email",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    headerClasses: "align-baseline pb-3"
  },
  {
    dataField: "li_description",
    text: "Product",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    classes: "",
    headerClasses: "align-baseline pb-3"
  },
  {
    dataField: "li_price_unit_amount",
    text: "Product Amount",
    sort: false,
    sortCaret: sortCaret,
    headerSortingClasses,
    formatter: ProductAmountColumnFormatter,
    classes: "text-center",
    headerClasses: "align-baseline pb-3 w-150px"
  },
  {
    dataField: "pi_amount_received",
    text: <AmountReceivedHeaderFormatter />,
    sort: false,
    sortCaret: sortCaret,
    headerSortingClasses,
    formatter: AmountReceivedColumnFormatter,
    classes: "text-center",
    headerClasses: "align-baseline pb-3 w-200px"
  },
  {
    dataField: "re_amount",
    text: "Refunded Amount",
    sort: false,
    sortCaret: sortCaret,
    headerSortingClasses,
    formatter: RefundAmountColumnFormatter,
    classes: "text-center",
    headerClasses: "text-center align-baseline pb-3 w-200px"
  },
  {
    dataField: "pi_status",
    text: <StatusHeaderFormatter />,
    sort: false,
    sortCaret: sortCaret,
    headerSortingClasses,
    formatter: StatusColumnFormatter,
    classes: "text-center",
    headerClasses: "align-baseline pb-3"
  },
];

const initialState = {
  columns: columns,
  defaultSorted: [{ dataField: "payment_intent_created", order: "desc" }],
  sizePerPageList: [
    { text: "10", value: 10 },
    { text: "20", value: 20 },
    { text: "50", value: 50 },
    { text: "100", value: 100 }
  ],
  queryParams: {
    sortOrder: "desc",
    sortField: "pi_created",
    pageNumber: 1,
    pageSize: 10,
  },
  filter: {
    searchText: "",
    status: "succeeded",
    dateFilter: {
      startDate: new Date(`01/01/${(new Date()).getFullYear()}`),
      endDate: new Date(`12/31/${(new Date()).getFullYear()}`),
      key: 'selection'
    },
  },
};

const state = initialState;

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
};

const useSalesList = globalHook(React, state, actions);

export default useSalesList;
