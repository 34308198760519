import React from "react";

const LastResumeUpdateFormatter = (
  cellContent,
  row
) => {
  return (
    <span>
      {row.profile && row.profile.hasOwnProperty('last_resume_update') && row.profile.last_resume_update}
    </span>
  )
}

export default LastResumeUpdateFormatter;
