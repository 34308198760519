import React from 'react';
import globalHook from 'use-global-hook';
import moment from 'moment';

const initField = {
  id: "",
  user_id: "",
  expired_at: null,
  life_time: false,
  one_month: true,
};

const initialState = {
  freeAccess: JSON.parse(JSON.stringify(initField)),
  loading: false,
};

const state = initialState;

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  resetFreeAccess: (store) => {
    store.setState({
      freeAccess: JSON.parse(JSON.stringify(initField)),
      loading: false
    });
  },
  isOneMonth: (store, access) => {
    let created_at = access.id !== "" ? access.created_at : moment().format("YYYY-MM-DD");
    let oneMonth = store.actions.addMonth(created_at, 1);
    return access.expired_at === oneMonth;
  },
  addMonth: (store, date = null, number_of_month) => {
    if(date === "" || !date) {
      date = moment().format('DD-MM-YYYY');
    }
    let startDate = moment(date);
    return moment(startDate).add(number_of_month, "M").format('YYYY-MM-DD');
  },
  save: async (store) => {
    store.setState({ loading: true });
    try {
      let url = `/api/admin/free_access`;

      let formData = new FormData();
      formData.append("id", store.state.freeAccess.id);
      formData.append("user_id", store.state.freeAccess.user_id);
      formData.append("expired_at", store.state.freeAccess.expired_at ? moment(store.state.freeAccess.expired_at).format("YYYY-MM-DD") : "");
      formData.append("life_time", store.state.freeAccess.life_time ? 1 : "");

      if(store.state.freeAccess.id !== "") {
        url += `/${formData.get("id")}`;
        formData.append("_method", "put");
      }

      let { data } = await global.axios.post(url, formData);

      let free_access = data.data;
      free_access.life_time = (free_access.expired_at === null || free_access.expired_at === "");

      store.setState({
        freeAccess: free_access,
        loading: false
      });

      return free_access;
    }
    catch ({ response }) {
      if(response.status === 422) {
        return response.data;
      }
    }
    store.setState({ loading: false });
  }
};

const useFreeAccess = globalHook(React, state, actions);

export default useFreeAccess;
