import React from 'react';
import globalHook from 'use-global-hook';
import { format, addDays } from "date-fns";

const initialState = {
  loading: false,
  dateFilter: {
    startDate: new Date(`01/01/${(new Date()).getFullYear()}`),
    endDate: new Date(`12/31/${(new Date()).getFullYear()}`),
    key: 'selection'
  },
  stats: {}
};

const state = initialState;

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  getQueryParams: (store) => {
    let dateStart = format(store.state.dateFilter.startDate, 'yyyy-MM-dd');
    let dateEnd = format(store.state.dateFilter.endDate, 'yyyy-MM-dd');

    let params = `?dateStart=${dateStart}&dateEnd=${dateEnd}`;

    return params;
  },
  fetchStats: async (store) => {
    store.setState({ loading: true });

    try {
      let params = store.actions.getQueryParams();
      let { data } = await global.axios({
        method: "GET",
        url: `/api/admin/onboarding_summary${params}`
      });
      store.setState({ stats: data.data });
    }
    catch (error) {
      console.log(error);
    }

    store.setState({ loading: false });
  }
};

const useOnboardingReport = globalHook(React, state, actions);

export default useOnboardingReport;
