import React from 'react';
import globalHook from 'use-global-hook';
import moment from "moment";
import { format, addDays } from "date-fns";

const initialState = {
  dataLoading: false,
  usersLoading: false,
  downloadsLoading: false,
  data: {},
  users: [],
  resumeDownloads: {
    count: 0,
    keys: [],
    values: []
  },
  coverLetterDownloads: {
    count: 0,
    keys: [],
    values: []
  },
  dateFilter: {
    startDate: new Date(`01/01/${(new Date()).getFullYear()}`),
    endDate: new Date(`12/31/${(new Date()).getFullYear()}`),
    key: 'selection'
  },
};

const state = initialState;

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  getQueryParams: (store) => {
    let dateStart = format(store.state.dateFilter.startDate, 'yyyy-MM-dd');
    let dateEnd = format(store.state.dateFilter.endDate, 'yyyy-MM-dd');

    let params = `?dateStart=${dateStart}&dateEnd=${dateEnd}`;

    return params;
  },
  fetchData: async (store) => {
    try {
      let params = store.actions.getQueryParams();
      let { data } = await global.axios({
        method: "GET",
        url: `/api/admin/dashboard_summary${params}`
      });
      store.setState({ data: data.data });
    }
    catch (error) {
      console.log(error);
    }
  },
  fetchDownloads: async (store) => {
    try {
      let params = store.actions.getQueryParams();
      let { data } = await global.axios({
        method: "GET",
        url: `/api/admin/download${params}`
      });

      let resume = {};
      let resumeCount = 0;
      let coverLetter = {};
      let coverLetterCount = 0;
      let downloads = data.data;

      for(let i in downloads) {
        let month = moment(downloads[i].created_at).format("MMMM");

        if(downloads[i].document_type.includes("Resume")) {
          resume[month] = resume[month] ? resume[month] : 0;
          resume[month] += 1;
          resumeCount += 1;
        } else {
          coverLetter[month] = coverLetter[month] ? coverLetter[month] : 0;
          coverLetter[month] += 1;
          coverLetterCount += 1;
        }
      }

      store.setState({
        resumeDownloads: {
          count: resumeCount,
          keys: Object.keys(resume),
          values: Object.values(resume)
        },
        coverLetterDownloads: {
          count: coverLetterCount,
          keys: Object.keys(coverLetter),
          values: Object.values(coverLetter)
        },
      });
    }
    catch (error) {
      console.log(error);
    }
  },
  fetchLatestRegistration: async (store) => {
    try{
      let params = `${store.actions.getQueryParams()}
        &page=1
        &pageSize=7
        &sortField=created_at
        &sortOrder=desc
        &userType=user
        &searchText=`;

      let { data } = await global.axios({
        method: "GET",
        url: `/api/admin/user${params}`
      });
      store.setState({
        users: data.data
      });
    }
    catch (error) {
      console.log(error);
    }
  },
};

const useDashboard = globalHook(React, state, actions);

export default useDashboard;
