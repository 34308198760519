import React from "react";

const CustomerColumnFormatter = (
  cellContent,
  row
) => {

  if(!row.user) {
    return "";
  }

  return (
    <div>
      {row.user.fname} {row.user.lname}
    </div>
  );
}

export default CustomerColumnFormatter;
