import React from "react";

const SubscriptionTypeColumnFormatter = (cellContent, row) => {
  return (
    <span
      className={`
        label label-lg font-weight-bold label-inline
        ${row.subscriptions.length > 0 || row.free_access ? "label-light-warning" : "label-light-primary"}`}
    >
      {row.free_access ? "FREE UPGRADE" : row.subscriptions.length > 0 ? "PREMIUM" : "FREE"}
    </span>
  )
}

export default SubscriptionTypeColumnFormatter;
