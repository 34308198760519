import React from "react";

const classes = {
  canceled: "label-light-default",
  succeeded: "label-light-success",
};

const StatusColumnFormatter = (
  cellContent,
  row
) => {
  return (
    <span
        className={`
        label label-inline
        ${row.pi_refunded ? "label-light-warning" : classes[row.pi_status]}`}
      >
      {row.pi_refunded ? "refunded" : row.pi_status}
    </span>
  );
}

export default StatusColumnFormatter;
