import React from 'react';
import globalHook from 'use-global-hook';

const initialState = {
  token: '',
  user: {},
};

const state = initialState;

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  isLoggedIn: () => {
    return localStorage.hasOwnProperty('token')
            && localStorage.token !== ""
            && localStorage.hasOwnProperty('user')
            && localStorage.user !== "";
  },
  login: async (store, postData) => {
    await global.axios({
      method: "GET",
      url: `${process.env.REACT_APP_BASE_URL}/sanctum/csrf-cookie`
    });
    try {
      let { data } = await global.axios({
        method: "POST",
        url: `/api/auth-token`,
        data: postData
      });

      let token = data.data.token.split("|")[1];
      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(data.data.user));

      store.setState({
        token: token,
        user: data.data.user
      });

      return true;
    } catch (err) {
      return false;
    }
  },
  logout: (store, history) => {

    localStorage.removeItem('token');
    localStorage.removeItem('user');

    store.setState({
      token: "",
      user: {}
    });

    history.push('/login')
  },
  setUserDetails: async (store) => {
    await store.setState({
      token: localStorage.getItem("token"),
      user: JSON.parse(localStorage.getItem("user"))
    });
  },
  updateUserDetails: (store, newUser) => {
    store.setState({ user: newUser })
    localStorage.setItem("user", JSON.stringify(newUser));
  },
  getUserRole: (store) => {
    let userRoles = {
      admin: "Administration",
      user: "Subscriber"
    }

    if(store.state.user.roles.length > 0) {
      return userRoles[store.state.user.roles[0]['name']];
    }

    return "Subscriber";
  },
};

const useAuth = globalHook(React, state, actions);

export default useAuth;
