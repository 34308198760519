import React from 'react';
import globalHook from 'use-global-hook';

const initialState = {
  loading: false,
  resume: {},
  resumes: [],
  resumeCount: 0,
};

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  fetchResumes: async (store, queryParams = {}, filters = {}) => {
    store.setState({ loading: true });

    try {
      let params = `?page=${queryParams.pageNumber}
        &pageSize=${queryParams.pageSize}
        &sortField=${queryParams.sortField}
        &sortOrder=${queryParams.sortOrder}
        &searchText=${filters.searchText}`;

      let { data } = await global.axios({
        method: "GET",
        url: `/api/admin/review_resume${params}`
      });

      store.setState({
        resumes: data.data,
        resumeCount: data.meta.total,
        loading: false
      });
    }
    catch (error) {
      console.log(error);
    }
  },
  fetchResume: async (store, id) => {
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/admin/review_resume/${id}`
      });
      store.setState({ resume: data.data });
    }
    catch(error){
      console.log(error);
    }
  },
  updateStatus: async (store, id, status) => {
    try {
      let { data } = await global.axios({
        method: "POST",
        url: `/api/admin/review_resume_update_status/${id}?status=${status}`
      });
      store.setState({ resume: data.data });
    }
    catch(error){
      console.log(error);
    }
  }
};

const useReviewResume = globalHook(React, initialState, actions);

export default useReviewResume;
