import React from 'react';
import globalHook from 'use-global-hook';

const fieldParentSlug = {
  "pre-written-responsibilities": {
    parentSlug: "job-title",
    placeholder: "Select Job Title"
  },
};

const initField = {
  id: "",
  field: "",
  slug: "",
  order: "",
};

const initialState = {
  fields: [],
  field: JSON.parse(JSON.stringify(initField)),
  fieldParentSlug: fieldParentSlug,
};

const state = initialState;

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  fetchFields: async (store, editor) => {
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/admin/editor_field?editor=${editor}`
      });
      store.setState({ fields: data.data });
    }
    catch (error) {
      console.log(error);
    }
  },
  fetchFieldBySlug: async (store, slug) => {
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/admin/fetch_editor_field_by_slug?fieldSlug=${slug}`
      });
      store.setState({ field: data.data });
      return data.data;
    }
    catch (error) {
      console.log(error);
    }
  },
  resetField: (store) => {
    store.setState({ field: JSON.parse(JSON.stringify(initField)) });
  },
};

const useEditorField = globalHook(React, state, actions);

export default useEditorField;
