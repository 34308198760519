import React from "react";

const ProductAmountColumnFormatter = (
  cellContent,
  row
) => {
  return (
    <>$ {Number(row.li_price_unit_amount).toFixed(2)} {row.cs_currency}</>
  );
}

export default ProductAmountColumnFormatter;
