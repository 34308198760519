import React from 'react';
import globalHook from 'use-global-hook';
import { format } from "date-fns";
import moment from "moment";
import { formatNumber } from "~app/helpers/format_functions";

const initialState = {
  loading: false,
  sales: [],
  salesCount: 0,
  salesTotal: 0,
  syncLoading: false,
  syncDataCount: 0,
  syncSuccess: false,
  allSales: [],
  salesStat: {
    data: [],
    categories: [],
    maxAmount: 10
  }
};

const state = initialState;

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  getSalesTotal: async (store, filters) => {
    try {
      let dateStart = format(filters.dateFilter.startDate, 'yyyy-MM-dd');
      let dateEnd = format(filters.dateFilter.endDate, 'yyyy-MM-dd');

      let params = `?dateStart=${dateStart}
        &dateEnd=${dateEnd}`;

      if(filters.hasOwnProperty('status')) {
        params += `&status=${filters.status}`;
      }

      if(filters.hasOwnProperty('searchText')) {
        params += `&searchText=${filters.searchText}`;
      }

      let { data } = await global.axios({
        method: "GET",
        url: `/api/admin/sales_total${params}`
      });

      let sales = data.data.sales;
      let salesStat = {
        data: [],
        categories: [],
        maxAmount: 10
      };

      let date = dateStart;
      let monthYear = moment(dateStart).format('MMMM YYYY');
      let monthYearEnd = moment(dateEnd).format('MMMM YYYY');

      while(monthYear !== monthYearEnd) {
        monthYear = moment(date).format('MMMM YYYY');
        let total = 0;

        for(let i in sales) {
          let salesMonthYear = moment(sales[i].pi_created).format("MMMM YYYY");
          if(monthYear === salesMonthYear) {
            total += sales[i]['pi_amount_received'];
          }
        }

        if(total > 0) {
          salesStat.data.push(total);
          salesStat.categories.push(monthYear);
          salesStat.maxAmount += total;
        }

        date = moment(date).add(1, 'M').format('YYYY-MM-DD');
      }

      store.setState({
        salesTotal: formatNumber(data.data.salesTotal),
        allSales: data.data.sales,
        salesStat: salesStat
      });
    }
    catch (error) {
      console.log(error);
    }

  },
  fetchSales: async (store, queryParams = {}, filters = {}) => {
    store.setState({ loading: true });

    try {
      let dateStart = format(filters.dateFilter.startDate, 'yyyy-MM-dd');
      let dateEnd = format(filters.dateFilter.endDate, 'yyyy-MM-dd');

      let params = `?page=${queryParams.pageNumber}
        &pageSize=${queryParams.pageSize}
        &sortField=${queryParams.sortField}
        &sortOrder=${queryParams.sortOrder}
        &status=${filters.status}
        &searchText=${filters.searchText}
        &dateStart=${dateStart}
        &dateEnd=${dateEnd}`;

      let { data } = await global.axios({
        method: "GET",
        url: `/api/admin/sales${params}`
      });

      store.setState({
        salesCount: data.meta.total
      });

      store.actions.modifySalesData(data.data, queryParams);
    }
    catch (error) {
      console.log(error);
    }
  },
  modifySalesData: (store, sales, queryParams) => {

    let count = (queryParams.pageSize*queryParams.pageNumber) - queryParams.pageSize + 1;

    for(let i in sales) {
      sales[i]['count'] = count++;
    }

    store.setState({
      sales: sales,
      loading: false
    });
  },
  syncSales: async (store) => {
    store.setState({ syncLoading: true });
    console.log("syncing sales..")
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/admin/sync_sales`
      });
      store.setState({
        syncDataCount: data.data.syncDataCount,
        syncUpdatedCount: data.data.syncUpdatedCount,
        syncUsersCount: data.data.syncUsersCount,
        syncSuccess: true
      });
    }
    catch (error) {
      console.log(error);
    }
  }
};

const useSales = globalHook(React, state, actions);

export default useSales;
