import React from 'react';
import globalHook from 'use-global-hook';

const initOption = {
  id: "",
  option: "",
  editor_field_id: "",
  parent_option_id: [""]
};

const initialState = {
  loading: false,
  options: [],
  optionTotal: 0,
  lastQuery: {},
  lastFilters: {},
  option: JSON.parse(JSON.stringify(initOption)),
};

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  resetOption: (store) => {
    store.setState({option: JSON.parse(JSON.stringify(initOption))});
  },
  fetchOption: async (store, optionId) => {
    store.setState({ loading: true });
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/admin/editor_field_option/${optionId}`
      });
      let option = JSON.parse(JSON.stringify(data.data));
      if(!option.parent_option_id || option.parent_option_id === "") {
        option.parent_option_id = [""];
      }
      store.setState({
        option: option,
        loading: false
      });
      return data.data;
    }
    catch (error) {
      store.setState({ loading: false });
      console.log(error);
    }
  },
  fetchOptions: async (store, queryParams = {}, filters = {}) => {
    store.setState({ loading: true });

    if(Object.keys(queryParams).length !== 0 && Object.keys(filters).length !== 0) {
      store.setState({
        lastQuery: queryParams,
        lastFilters: filters
      });
    }

    if(Object.keys(queryParams).length === 0){
      queryParams = store.state.lastQuery;
    }

    if(Object.keys(filters).length === 0){
      filters = store.state.lastFilters;
    }

    try {
      let params = `?page=${queryParams.pageNumber}
        &pageSize=${queryParams.pageSize}
        &sortField=${queryParams.sortField}
        &sortOrder=${queryParams.sortOrder}
        &searchText=${filters.searchText}
        &fieldId=${filters.fieldId}
        &parentOptionId=${filters.parentOptionId}`;

      let { data } = await global.axios({
        method: "GET",
        url: `/api/admin/editor_field_option${params}`
      });

      store.setState({ optionTotal: data.meta.total });
      store.actions.modifyData(data.data, queryParams);
    }
    catch (error) {
      console.log(error);
    }
  },
  modifyData: (store, data, queryParams) => {
    let count = (queryParams.pageSize*queryParams.pageNumber) - queryParams.pageSize + 1;
    for(let i in data) {
      data[i]['count'] = count++;
    }
    store.setState({
      options: data,
      loading: false
    });
  },
  deleteOption: async (store, option_id) => {
    try {
      await global.axios({
        method: "DELETE",
        url: `/api/admin/editor_field_option/${option_id}`
      });
    }
    catch (error) {
      console.log(error);
    }
  },
  saveOption: async (store, formData) => {
    try {
      let url = `/api/admin/editor_field_option`;

      if(formData.get("id") !== "") {
        url = `/api/admin/editor_field_option/${formData.get("id")}`;
        formData.append("_method", "put");
      }

      let { data } = await global.axios.post(url, formData);
      store.setState({option: data.data});
      return data.data;
    }
    catch ({ response }) {
      if(response.status === 422) {
        return response.data;
      }
    }
  },
  fetchOptionsByParentSlug: async (store, slug) => {
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/admin/fetch_option_by_parent_slug?slug=${slug}`
      });
      return data.data;
    }
    catch (error) {
      console.log(error);
    }
  }
};

const useEditorFieldOption = globalHook(React, initialState, actions);

export default useEditorFieldOption;
