import React from "react";
import globalHook from 'use-global-hook';

const initialState = {
    select_watcher: 1,
    change_template_dialog: false,
    open_dialog_watcher: 1
}

const state = JSON.parse(JSON.stringify(initialState));

const actions = {
    setState: (store, newState) => {
        store.setState(newState);
    },
    setSelectWatcher(store, val) {
        const newWatcherVal = store.state.select_watcher + val;
        store.setState({ select_watcher: newWatcherVal });
    },
    openChangeTemplate(store) {
        const newDialogState = !store.state.change_template_dialog;
        store.setState({ change_template_dialog: newDialogState });
    },
    setDialogWatcher(store, val) {
        const newWatcherVal = store.state.open_dialog_watcher + val;
        store.setState({ open_dialog_watcher: newWatcherVal });
        console.log(' storeyahe ', store.state.open_dialog_watcher);
    }
}

const useResumeChangeTemplate = globalHook(React, state, actions);

export default useResumeChangeTemplate;
