import React from "react";

const AmountColumnFormatter = (
  cellContent,
  row
) => {
  return (
    <div>
      $ {Number(row.payment_amount).toFixed(2)}
    </div>
  );
}

export default AmountColumnFormatter;
