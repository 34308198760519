import React from "react";

const HomeLocationFormatter = (
  cellContent,
  row
) => {
  return (
    <span>
      {row.profile && row.profile.hasOwnProperty('employee_live') && row.profile.employee_live}
    </span>
  )
}

export default HomeLocationFormatter;
