import React from "react";

const ResumeNameColumnFormatter = (
  cellContent,
  row
) => {
  return (
    <span>
      {row.resume.data["job-title"] !== "" ? row.resume.data["job-title"] : row.resume_ruid}
    </span>
  )
}

export default ResumeNameColumnFormatter;
